@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $black;
	font-family: 'Archivo', sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.desktop{
	display: none;
}
@include tablet-up{
	.mobile{
		display: none;
	}
	.desktop{
		display: block;
	}
}

.btn{
	border: none;
	background-color: transparent;
	box-shadow: none;
	line-height: 1;
	text-transform: uppercase;
	padding: 8px 20px;
	display: inline-block;
	font-size: 18px;
	&.btn-black{
		background-color: $black;
		color: $white;
		@include hover-focus{
			background-color: $white;
			color: $black;
		}
	}
	&.btn-white{
		background-color: $white;
		color: $black;
		@include hover-focus{
			background-color: $black;
			color: $white;
		}
	}
}

h2{
	font-size: 22px;
	color: $white;
	text-transform: uppercase;
	margin: 0 0 30px;
	font-weight: 900;
	@include tablet-up{
		font-size: 80px;
		margin-bottom: 80px;
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
		color: $black;
		@include hover-focus{
			color: $white;
		}
	}
}

header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	padding: 20px 0;
	text-align: center;
	.header-logo{
		display: inline-block;
		margin-bottom: 20px;
	}
	.nav{
		list-style: none;
		padding: 0;
		margin: 0;
		@include flex(row,center,initial);
		flex-wrap: wrap;
		gap: 20px;
		margin-bottom: 20px;
		li{
			a{
				color: $black;
				font-size: 16px;
				text-transform: uppercase;
				@include hover-focus{
					color:$white;
				}
			}
		}
	}
	@include tablet-up{
		.container{
			@include flex(row,space-between,center);
			gap: 20px;
			.header-logo{
				margin-bottom: 0;
			}
			.nav{
				li{
					a{
						font-size: 20px;
					}
				}
				margin-bottom: 0;
			}
		}
	}
}

main {
	position: relative;
	section{
		padding: 60px 0;
		@include tablet-up{
			padding: 100px 0;
		}
		&.hero{
			padding: 0;
			img{
				min-height: 100vh;
				width: 100%;
				object-fit: cover;
			}
		}

		&.video{
			padding-bottom: 0;
			.video-slider{
				.slick-arrow{
					position: absolute;
					font-size: 40px;
					padding: 0;
					border: none;
					color: $white;
					background-color: transparent;
					z-index: 99;
					top: calc(50% - 20px);
					&.slick-prev{
						left: 10px;
					}
					&.slick-next{
						right: 10px;
					}
				}
				a{
					display: block;
					position: relative;
					iframe{
						max-height: 700px;
						object-fit: cover;
						width: 100%;
						height: 300px;
						@include phone-up{
							height: 450px;
						}
						@include tablet-up{
							height: 600px;
						}
					}
				}
			}
		}

		&.music{
			.container{
				.wrapper{
					@include flex(column,initial,initial);
					gap: 30px;
					.featured-music-item{
						position: relative;
						p{
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							padding: 20px;
							background-color: rgba(0, 0, 0, 0.8);
							z-index: 9;
							text-transform: uppercase;
							line-height: 1.4;
							margin: 0;
						}
						img{
							width: 100%;
							max-width: 580px;
							object-fit: cover;
						}
					}
					.music-items{
						@include flex(column,initial,initial);
						gap: 30px;
						.music-item{
							position: relative;
							p{
								position: absolute;
								left: 0;
								bottom: 0;
								width: 100%;
								padding: 20px;
								background-color: rgba(0, 0, 0, 0.8);
								z-index: 9;
							  text-transform: uppercase;
								line-height: 1.4;
								margin: 0;
							}
						}
					}
					margin-bottom: 40px;
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						@include flex(row,space-between,initial);
						gap: 40px;
						.featured-music-item{
							width: 50%;
							p{
								transition: all .4s;
								opacity: 0;
								pointer-events: none;
								transform: translateY(10px);
							}
							@include hover-focus{
								p{
									opacity: 1;
									transform: translateY(0);
									pointer-events: all;
								}
							}
						}
						.music-items{
							width: 50%;
							@include flex(row,flex-start,flex-start);
							flex-wrap: wrap;
							.music-item{
								width: calc(50% - 20px);
								p{
									transition: all .4s;
									opacity: 0;
									pointer-events: none;
									text-align: center;
									@include flex(column,center,center);
								}
								@include hover-focus{
									p{
										opacity: 1;
										pointer-events: all;
										height: 100%;
									}
								}
							}
						}
					}
				}
			}
		}

		&.tour{
			background-color: $white;
			color: $black;
			h2{
				color: $black;
			}
			#events{
				@include flex(column,initial,initial);
				gap: 40px;
				.event{
					border-bottom: solid 1px $black;
					padding-bottom: 20px;
					text-align: center;
					text-transform: uppercase;
					.event-date{
						margin-bottom: 10px;
						font-weight: 900;
					}
					.event-venue{
						margin-bottom: 10px;						
					}
				}
			}
			@include tablet-up{
				#events{
					.event{
						text-align: left;
						padding-bottom: 40px;
						.event-info{
							@include flex(row,space-between,center);
						}
					}
				}
			}
		}

		&.updates{
			.container{
				.wrapper{
					@include flex(column-reverse,initial,initial);
					gap: 30px;
					.side{
						&.image{
							text-align: center;
							img{
								max-height: 400px;
								display: inline-block;
							}
						}
						&.content{
							form{
								@include flex(column,initial,initial);
								gap: 20px;
								input{
									&[type=text],&[type=email]{
										font-size: 18px;
										text-transform: uppercase;
										padding: 15px;
										background-color: transparent;
										border: none;
										border-bottom: solid 1px $white;
										&::placeholder{
											color: $white;
										}
									}
								}
								.btn{
									margin-top: 40px;
								}
							}
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						@include flex(row,space-between,center);
						gap: 60px;
						.side{
							width: 50%;
							&.image{
								img{
									max-height: initial;
								}
							}
						}
					}
				}
			}
			@media screen and (min-width:1400px) {
				position: relative;
				.container{
					.wrapper{
						.side{
							&.image{
								img{
									position: absolute;
									height: calc(100% + 80px);
									top: -40px;
									right: 40px;
									transform: rotate(3deg);
								}
							}
						}
					}
				}
			}
		}

		&.shop{
			background-color: $white;
			h2{
				color: $black;
				text-align: center;
			}
			.wrapper{
				display: grid;
				gap: 20px;
				grid-template-columns: repeat(
					auto-fit,
					minmax(
						300px,
						1fr
					)
				);
				.shop-item{
					a{
						img{
							width: 100%;
						}
						p{
							color: $black;
							font-weight: 700;
							font-size: 18px;
							text-align: center;
						}
					}
				}
			}
		}

	}
}

footer {
	background-color: $black;
	padding: 60px;
	.container{
		@include flex(column,initial,initial);
		text-align: center;
		gap: 30px;
		.side{
			img{
				display: inline-block;
				margin-bottom: 10px;
			}
			.social{
				a{
					color: $white;
				}
			}
		}
		.copyright{
			img{
				display: inline-block;
				margin-bottom: 10px;
			}
			line-height: 1.4;
			font-size: 14px;
			text-transform: uppercase;
		}
	}
	@include tablet-up{
		padding: 160px;
		.container{
			@include flex(row, space-between, center);
			.side{
				text-align: left;
				width: 50%;
				img{
					margin-bottom: 40px;
				}
				.social{
					justify-content: flex-start;
				}
			}
			.copyright{
				width: 50%;
				text-align: right;
				p{
					a{
						@include hover-focus{
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

.popup-laylo{
	opacity: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 9999999;
	transition: all .4s;
	.container{
		height: 100vh;
		@include flex(row,center,center);
		.wrapper{
			position: relative;
			padding: 40px 30px;
			color: $black;
			background-color: $white;
			max-width: 400px;
			text-align: center;
			button{
				position: absolute;
				top: 0;
				right: 0;
			}
			p{
				line-height: 1.4;
			}
			.btn{
				border: solid 1px transparent;
				@include hover-focus{
					border-color: $black;
				}
			}
		}
	}
	&.active{
		opacity: 1;
		pointer-events: all;
	}
}